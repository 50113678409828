import {Layout, Page, SkeletonBodyText} from '@shopify/polaris-internal';
import {useI18n} from '@shopify-internal/react-i18n';
import React from 'react';

import {EnhancedCard, SkeletonPageActions} from '~/components';
import {useIdFromUrl, usePreviousRouteInfo} from '~/hooks';
import {DetailsPageMode} from '~/types/shared';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {synonymGroupURL} from '~/utils/urls';

import translations from './translations';

export function SynonymGroupDetailsSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));
  const id = useIdFromUrl();
  const mode = id ? DetailsPageMode.Update : DetailsPageMode.Create;

  const backAction = usePreviousRouteInfo({
    content: i18n.translate('SynonymGroupDetailsSkeleton.navigation.synonyms'),
    url: synonymGroupURL(),
  });

  return (
    <Page
      title={
        mode === DetailsPageMode.Update
          ? i18n.translate('SynonymGroupDetailsSkeleton.editTitle')
          : i18n.translate('SynonymGroupDetailsSkeleton.createTitle')
      }
      backAction={backAction}
    >
      <Layout>
        <Layout.Section>
          <EnhancedCard
            title={i18n.translate(
              'SynonymGroupDetailsSkeleton.synonymCard.title',
            )}
          >
            <SkeletonBodyText lines={3} />
          </EnhancedCard>
        </Layout.Section>
        <Layout.Section>
          <EnhancedCard
            title={i18n.translate(
              'SynonymGroupDetailsSkeleton.titleCard.title',
            )}
          >
            <SkeletonBodyText lines={1} />
          </EnhancedCard>
        </Layout.Section>
        <Layout.Section>
          <SkeletonPageActions />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
