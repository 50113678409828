import {useEffect, useState} from 'react';

import {useFetcher} from '~/hooks';
import type {
  EnableMetasVariablesData,
  EnableMetasVariablesVariables,
} from '~/routes/api.setup/apiSetupAction';
import {
  MetaobjectUserErrorCode,
  StandardMetafieldDefinitionsEnableUserErrorCode,
} from '~/types/graphql/core-types';

import {notify} from '../context';

export function useEnableMetaDefinitions() {
  const fetcher = useFetcher<
    [EnableMetasVariablesVariables, EnableMetasVariablesData],
    never
  >();
  const [
    {enabledDefinitions, enablingDefinitions},
    setEnablingDefinitionsState,
  ] = useState({enablingDefinitions: false, enabledDefinitions: false});
  const [userError, setUserError] = useState<Error>();

  useEffect(() => {
    if (enablingDefinitions || enabledDefinitions || userError) {
      return;
    }
    (async function enableMetafields() {
      try {
        setEnablingDefinitionsState({
          enablingDefinitions: true,
          enabledDefinitions: false,
        });

        const {data, errors} = await fetcher.submit(
          {action: 'enableMetas'},
          {action: '/api/setup', encType: 'application/json', method: 'post'},
        );

        const unexpectedGQLErrors = errors ?? [];

        // These are GQL layer errors, not user errors. Therefor we throw only the first one to have complete information inside bugsnag reports.
        if (unexpectedGQLErrors.length > 0) {
          unexpectedGQLErrors.forEach((error) => {
            notify(error.message);
          });
          throw unexpectedGQLErrors[0];
        }

        const unexpectedMetafieldErrors = (
          data?.standardMetafieldDefinitionsEnable?.userErrors || []
        ).filter(
          ({code}) =>
            code !== StandardMetafieldDefinitionsEnableUserErrorCode.Taken,
        );

        const unexpectedMetaobjectErrors = (
          data?.standardMetaobjectDefinitionEnable?.userErrors || []
        ).filter(
          ({code}) =>
            code !== MetaobjectUserErrorCode.ObjectFieldTaken &&
            code !== MetaobjectUserErrorCode.Taken,
        );

        const unexpectedUserErrors = [
          ...unexpectedMetafieldErrors,
          ...unexpectedMetaobjectErrors,
        ];

        if (unexpectedUserErrors.length > 0) {
          throw new Error(
            unexpectedUserErrors
              .map((userError) => userError.message)
              .join('&'),
          );
        }

        setEnablingDefinitionsState({
          enablingDefinitions: false,
          enabledDefinitions: true,
        });
      } catch (error: any) {
        setUserError(error);
        setEnablingDefinitionsState({
          enablingDefinitions: false,
          enabledDefinitions: false,
        });
      }
    })();
  }, [enabledDefinitions, enablingDefinitions, fetcher, userError]);

  return {enablingDefinitions, error: userError};
}
