import {matchPath, useLocation, useNavigation} from '@remix-run/react';
import {Loading} from '@shopify/app-bridge-react';
import React from 'react';

import {FilterDetailsSkeleton} from '~/routes/filters.$id/FilterDetailsSkeleton';
import {FiltersListSkeleton} from '~/routes/filters._index/FiltersListSkeleton';
import {ProductRecommendationsDetailsSkeleton} from '~/routes/product-recommendations.$id/ProductRecommendationsDetailsSkeleton';
import {ProductRecommendationsListSkeleton} from '~/routes/product-recommendations._index/ProductRecommendationsListSkeleton';
import {ProductBoostsDetailsSkeleton} from '~/routes/search.product-boosts.$id/ProductBoostsDetailsSkeleton';
import {ProductBoostsListSkeleton} from '~/routes/search.product-boosts._index/ProductBoostsListSkeleton';
import {SynonymGroupDetailsSkeleton} from '~/routes/search.synonyms.$id/SynonymGroupDetailsSkeleton';
import {SynonymGroupListSkeleton} from '~/routes/search.synonyms._index/SynonymGroupListSkeleton';
import {SettingsSkeleton} from '~/routes/settings._index';
import {
  filtersURL,
  overviewURL,
  productBoostsURL,
  productRecommendationsURL,
  searchURL,
  settingsURL,
  synonymGroupURL,
} from '~/utils/urls';

import {useBugsnagContext} from '../AppSetupContext';

const pathConfig: {pathname: string; Component: React.FunctionComponent}[] = [
  {pathname: '/auth/login', Component: () => <Loading />},
  {pathname: overviewURL(), Component: () => <Loading />},
  {pathname: searchURL(), Component: () => <Loading />},
  {pathname: settingsURL(), Component: SettingsSkeleton},
  {pathname: filtersURL(), Component: FiltersListSkeleton},
  {pathname: filtersURL(':id'), Component: FilterDetailsSkeleton},
  {pathname: productBoostsURL(), Component: ProductBoostsListSkeleton},
  {pathname: productBoostsURL(':id'), Component: ProductBoostsDetailsSkeleton},
  {pathname: synonymGroupURL(), Component: SynonymGroupListSkeleton},
  {pathname: synonymGroupURL(':id'), Component: SynonymGroupDetailsSkeleton},
  {
    pathname: productRecommendationsURL(),
    Component: ProductRecommendationsListSkeleton,
  },
  {
    pathname: productRecommendationsURL(':id'),
    Component: ProductRecommendationsDetailsSkeleton,
  },
];

export function Main({children}: {children: React.ReactNode}) {
  const {notify} = useBugsnagContext();
  const navigation = useNavigation();
  const location = useLocation();

  const isNavigatingToSamePath =
    navigation.state === 'loading' &&
    location.pathname === navigation.location?.pathname;

  // I know you think you should split this to two if statements, but you shouldn't
  // If you remove the first node and only render children, it will unmount the previous component
  // You can see this by going to any list and changing the sort, then put a useEffect to log mount/unmount, and we need to make sure this doesn't unmount on query param changes.
  if (isNavigatingToSamePath || navigation.state !== 'loading') {
    return (
      <>
        {isNavigatingToSamePath ? <Loading /> : null}
        {children}
      </>
    );
  }

  const match = pathConfig.find(({pathname}) =>
    matchPath(pathname, navigation.location.pathname),
  );

  if (!match) {
    notify(
      `No match found for ${navigation.location.pathname}, defaulting to the Loading component`,
    );
    return <Loading />;
  }

  return <match.Component />;
}
