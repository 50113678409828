import {Card, EmptyState, Link, Page} from '@shopify/polaris-internal';
import {useI18n} from '@shopify-internal/react-i18n';
import React from 'react';

import {createI18nConfig} from '~/utils/createI18nConfig';

import {emptyStateIllustration} from './illustrations';
import translations from './translations';

/**
 * Shows a generic error screen when some type of global error occurs.
 */
export function ShowError() {
  const [i18n] = useI18n(createI18nConfig(translations));

  // Key for external links to work: we need to provide a new linkComponent to our context, or else external links will not work properly.
  return (
    <Page>
      <Card>
        <EmptyState
          heading={i18n.translate('ShowError.heading')}
          image={emptyStateIllustration}
        >
          {i18n.translate('ShowError.content', {
            statusPageLink: (
              <Link url="https://status.shopify.com" target="_blank">
                {i18n.translate('ShowError.statusPage')}
              </Link>
            ),
          })}
        </EmptyState>
      </Card>
    </Page>
  );
}
