import {Card, EmptyState, Page} from '@shopify/polaris-internal';
import {useI18n} from '@shopify-internal/react-i18n';
import React from 'react';

import {createI18nConfig} from '~/utils/createI18nConfig';

import {notFoundIllustration} from './illustrations';
import translations from './translations';

export function NotFound() {
  const [i18n] = useI18n(createI18nConfig(translations));

  return (
    <Page>
      <Card>
        <EmptyState
          heading={i18n.translate('NotFound.heading')}
          image={notFoundIllustration}
          /* TODO Remix - bring this back once https://github.com/remix-run/remix/issues/10005 is fixed
          action={{
            content: i18n.translate('NotFound.goToHome'),
            url: '/',
          }}
          */
        >
          <p>{i18n.translate('NotFound.retryInstruction')}</p>
        </EmptyState>
      </Card>
    </Page>
  );
}
