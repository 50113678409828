import {
  BlockStack,
  Box,
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris-internal';
import {useI18n} from '@shopify-internal/react-i18n';
import React from 'react';

import {SkeletonPageActions} from '~/components';
import {createI18nConfig} from '~/utils/createI18nConfig';

import translations from './translations';

export function SettingsSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));

  const skeletonSectionMarkup = (
    <BlockStack gap="200">
      <SkeletonDisplayText size="small" />
      <BlockStack>
        <SkeletonBodyText lines={3} />
      </BlockStack>
    </BlockStack>
  );

  return (
    <Page title={i18n.translate('Settings.title')}>
      <Layout>
        <Layout.AnnotatedSection
          title={i18n.translate('Settings.filters.title')}
        >
          <Card padding="400">{skeletonSectionMarkup}</Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={i18n.translate('Settings.searchRelevance.title')}
          description={<SkeletonBodyText lines={2} />}
        >
          <Card padding="0">
            <Box padding="400">
              <BlockStack gap="800">
                {skeletonSectionMarkup}
                {skeletonSectionMarkup}
                {skeletonSectionMarkup}
              </BlockStack>
            </Box>
            <Box padding="400" background="bg-surface-secondary">
              <SkeletonBodyText lines={1} />
            </Box>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <SkeletonPageActions />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
