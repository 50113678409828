import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useRouteLoaderData,
} from '@remix-run/react';
import {AppProvider} from '@shopify/polaris-internal';
import polarisStyles from '@shopify/polaris-internal/styles.css?url';
import {boundary} from '@shopify/shopify-app-remix/server';
import {CurrencyCode, I18nContext} from '@shopify-internal/react-i18n';
import React, {useRef} from 'react';

import {TrackPageView} from '~/foundation/App/components';
import {AppErrorBoundary} from '~/foundation/AppErrorBoundary/AppErrorBoundary';
import {
  AppSetupContext,
  ShowError,
  SmartLink,
} from '~/foundation/AppSetupContext';
import {notify} from '~/foundation/AppSetupContext/context';
import {useI18nManager} from '~/foundation/AppSetupContext/hooks';
import {Main} from '~/foundation/Main';
import {Navigation} from '~/foundation/Navigation';
import {WebVitals} from '~/foundation/WebVitals';
import {PreviousRouteInfoProvider, useSessionStorage} from '~/hooks';
import {ALL_BETA_FLAG_VALUES} from '~/utils/betaFlags';

import {genericGraphQL} from './.server/utils/genericGraphQL';
import {SharedDataContextQueryString} from './.server/utils/graphql/SharedDataContextQuery.graphql';
import {inMemoryCache} from './.server/utils/inMemoryCache';
import {authenticate} from './shopify.server';

import type {HeadersFunction, LoaderFunctionArgs} from '@remix-run/node';

import {
  APP_ENV,
  BUGSNAG_REMIX_API_KEY,
  NODE_ENV,
  REVISION,
  SHOPIFY_API_KEY,
  SPIN,
} from '~/.server/utils/env';

export const links = () => [{rel: 'stylesheet', href: polarisStyles}];

export const loader = async ({request}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  // Note: the query params exist when the app is launched, but not when you navigate to different pages.
  // Because of this, we need to put this data into a ref so that it persists across navigations.
  const searchParams = new URL(request.url).searchParams;
  const locale = searchParams.get('locale') ?? 'en-US';

  const {isAuthPath, sharedData} = await (async () => {
    const isAuthPath = url.pathname.startsWith('/auth');
    if (isAuthPath) {
      return {isAuthPath, sharedData: null};
    }

    const fullAdmin = await authenticate.admin(request);

    // here is a hack - we can use this to determine if this is being launched for the first time
    // if so, let's clear the entire cache for this user
    const isFirstLaunch = searchParams.has('shop');
    if (isFirstLaunch) {
      inMemoryCache.clear(fullAdmin);
    }

    const sharedData = await genericGraphQL(
      fullAdmin.admin,
      SharedDataContextQueryString,
      {
        variables: {
          enabledBetasNames: ALL_BETA_FLAG_VALUES,
        },
      },
    );

    return {isAuthPath, sharedData};
  })();

  return json({
    data: {
      apiKey: SHOPIFY_API_KEY,
      host: searchParams.get('host') ?? '',
      nodeEnv: NODE_ENV,
      appEnv: APP_ENV,
      revision: REVISION,
      bugsnagApiKey: BUGSNAG_REMIX_API_KEY,
    },
    sharedData,
    isSpin: Boolean(SPIN),
    isAuthPath,
    locale,
    lang: locale.split('-')[0],
  });
};

export default function App() {
  return <Outlet />;
}

export function Layout({children}: {children: React.ReactNode}) {
  const loaderData = useRouteLoaderData<typeof loader>('root');
  const {
    locale = 'en-US',
    lang = 'en',
    data = null,
    isSpin = false,
  } = useRef(loaderData).current ?? {};
  const {isAuthPath = false, sharedData} = loaderData ?? {};

  const bodyMarkup = (() => {
    if (isAuthPath || !data?.host) {
      return <AnonymousContext data={{locale}}>{children}</AnonymousContext>;
    }

    if (!sharedData?.data) {
      notify(
        `Unexpected state: Trying to load the main app without sharedData`,
      );
      return (
        <AnonymousContext data={{locale}}>
          <ShowError />
        </AnonymousContext>
      );
    }

    if (sharedData.errors && sharedData.errors.length > 0) {
      sharedData.errors.forEach((error) => {
        notify(error.message);
      });
      return (
        <AnonymousContext data={{locale}}>
          <ShowError />
        </AnonymousContext>
      );
    }

    return (
      <AppSetupContext data={data} sharedData={sharedData.data}>
        <Navigation />
        <WebVitals config={data} />
        <PreviousRouteInfoProvider>
          <TrackPageView />
          <Main>{children}</Main>
        </PreviousRouteInfoProvider>
      </AppSetupContext>
    );
  })();

  return (
    <html lang={lang} dir="ltr">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {data ? <meta name="shopify-api-key" content={data.apiKey} /> : null}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <Meta />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Links />
        <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js" />
        {/* This is used for React devtools: https://github.com/Shopify/discovery-app/pull/4591 */}
        {isSpin ? <script async src="http://localhost:8097" /> : null}
      </head>
      <body>
        {bodyMarkup}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  return <AppErrorBoundary />;
}

export const headers: HeadersFunction = (headersArgs) => {
  return boundary.headers(headersArgs);
};

function AnonymousContext({
  children,
  data,
}: {
  children: React.ReactNode;
  data: {locale: string};
}) {
  const [preferredLocale] = useSessionStorage('preferredLocale', '');
  const [preferredCurrencyCode] = useSessionStorage(
    'preferredCurrencyCode',
    '' as CurrencyCode,
  );
  const {i18nManager, i18n} = useI18nManager({
    locale: preferredLocale || data.locale,
    currencyCode: preferredCurrencyCode || CurrencyCode.Usd,
  });

  return (
    <I18nContext.Provider value={i18nManager}>
      {i18n ? (
        <AppProvider i18n={i18n.translations} linkComponent={SmartLink}>
          {children}
        </AppProvider>
      ) : null}
    </I18nContext.Provider>
  );
}
